import React from 'react';
import imgBg from '../assets/background/manageProperty.jpg'
import AOS from "aos";
import "aos/dist/aos.css";
const ManagePropertyBanner = ({props}) => {
  AOS.init();
  return (
    <div data-aos="flip-up" data-aos-duration="1000"  className="relative overflow-hidden bg-white my-10">
      {/* Decorative background image and gradient */}
      <div aria-hidden="true" className="absolute inset-0">
        <div className="absolute inset-0 mx-auto max-w-7xl overflow-hidden xl:px-8">
          <img
            src={imgBg}
            alt=""
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div className="absolute inset-0 bg-white bg-opacity-25"></div>
        <div className="absolute inset-0 bg-gradient-to-t from-white via-white mt-10 "></div>
      </div>

      {/* Callout */}
      <section
        aria-labelledby="sale-heading"
        className="relative mx-auto flex max-w-7xl flex-col items-center px-4 pt-32 text-center sm:px-6 lg:px-8 mt-20 lg:mt-[6rem]"
      >
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h2
            id="sale-heading"
            className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl"
          >
            Administramos tu propiedad
          </h2>
          {/* <p className="mx-auto mt-4 max-w-xl text-xl text-gray-600">
          Simplificamos y optimizamos la administración de tu propiedad en <strong>JDYC Negocios Inmobiliarios</strong>, experiencia de gestión sin complicaciones.
          </p> */}
          <p className="mx-auto mt-4 max-w-xl text-xl text-gray-600">
            {props?.field01?.content}
          </p>

          <a
          // eslint-disable-next-line 
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/5493547565707/?text=%20Hola,%20quisiera%20informacion%20sobre%20administración%20de%20una%20propiedad%20"
            className="mt-6 inline-block w-full rounded-md border border-transparent bg-gray-900 px-8 py-3 font-medium text-white hover:bg-cyan-800 sm:w-auto"
          >
            Contactanos
          </a>
        </div>
      </section>

      {/* Testimonials */}
      {/* <section
        aria-labelledby="testimonial-heading"
        className="relative mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8 lg:py-32"
      >
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h2
            id="testimonial-heading"
            className="text-2xl font-bold tracking-tight text-gray-900"
          >
            What are people saying?
          </h2>

          <div className="mt-16 space-y-16 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
            <blockquote className="sm:flex lg:block">
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                aria-hidden="true"
                className="flex-shrink-0 text-gray-300"
              >
                <path
                  d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z"
                  fill="currentColor"
                />
              </svg>
              <div className="mt-8 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-10">
                <p className="text-lg text-gray-600">
                  My order arrived super quickly. The product is even better than I hoped it would be. Very happy customer over here!
                </p>
                <cite className="mt-4 block font-semibold not-italic text-gray-900">
                  Shehab, England
                </cite>
              </div>
            </blockquote>
            <blockquote className="sm:flex lg:block">
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                aria-hidden="true"
                className="flex-shrink-0 text-gray-300"
              >
                <path
                  d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z"
                  fill="currentColor"
                />
              </svg>
              <div className="mt-8 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-10">
                <p className="text-lg text-gray-600">
                  I had to return a purchase that didn’t fit. The whole process was so simple that I ended up ordering two new items!
                </p>
                <cite className="mt-4 block font-semibold not-italic text-gray-900">
                  Najib, Malaysia
                </cite>
              </div>
            </blockquote>
            <blockquote className="sm:flex lg:block">
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                aria-hidden="true"
                className="flex-shrink-0 text-gray-300"
              >
                <path
                  d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z"
                  fill="currentColor"
                />
              </svg>
              <div className="mt-8 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-10">
                <p className="text-lg text-gray-600">
                  Now that I’m on holiday for the summer, I’ll probably order a few more shirts. It’s just so convenient, and I know the quality will always be there.
                </p>
                <cite className="mt-4 block font-semibold not-italic text-gray-900">
                  Yousef, New York
                </cite>
              </div>
            </blockquote>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default ManagePropertyBanner;