import React from "react";

const BrochureCard = ({ item }) => {
  return (
    <div className="cursor-pointer hover:shadow-lg bg-[#f3f4f6] flex items-center px-6 py-2 gap-6 border border-[#f3f4f6] ">
      <div className={`w-[20%] p-3 flex items-center justify-center rounded-full ${item.color}`}>
        <img src={item.img} alt="icon-brochure" className="w-12" />
      </div>
      <div>
        <h3 className="font-bold">{item.title}</h3>
        <p className="font-thin">{item.text}</p>
      </div>
    </div>
  );
};

export default BrochureCard;
