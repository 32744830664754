import React from "react";
import BackgroungImage from "../assets/background/turismo005.jpg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Tourism() {
  AOS.init();
  return (
    <div 
   
  // 
     id="turismo" className="mb-14">
      <section
       data-aos="fade-up"
       data-aos-duration="1500"  
        style={{ backgroundImage: `url(${BackgroungImage})` }}
        className="w-full bg-cover bg-center min-h-[30vw]  bg-opacity-90 opacity-100 flex"
      >
        <div className="flex flex-col items-center justify-around gap-20 w-full py-20 ">
            
          <h2 className="text-center lg:text-[4.5rem] text-[3.5rem] font-semibold drop-shadow-xl leading-[3.8rem] p-2 text-gray-50">
            Elegí tus proximas vacaciones
          </h2>
          <Link to={'/propiedades?tipo=turismo'} >
            
          <h2 className="text-center text-lg font-semibold leading-8 text-gray-50 bg-gray-950 w-[16rem] py-2 ">
            Alquiler Temporal
          </h2>
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Tourism;
