import HomeIcon from "../assets/icons/home.png";
import DepotIcon from "../assets/icons/warehouse.png";
import BuildIcon from "../assets/icons/building.png";
import FactoryIcon from "../assets/icons/factory.png";
import LandIcon from "../assets/icons/land.png";
import EdfIcon from "../assets/icons/skyscraper.png";
import StoreIcon from "../assets/icons/store.png";


export const dataButtonsBrochure = [
    {
      title: "Casas",
      text: "25 propiedades",
      color: "bg-red-300",
      img: HomeIcon,
    },
    {
      title: "Turismo",
      text: "10 propiedades",
      color: "bg-orange-300",
      img: DepotIcon,
    },
    {
      title: "Oficinas",
      text: "25 propiedades",
      color: "bg-cyan-300",
      img: BuildIcon,
    },
    {
      title: "Industrial",
      text: "25 propiedades",
      color: "bg-gray-50",
      img: FactoryIcon,
    },
    {
      title: "Lotes",
      text: "25 propiedades",
      color: "bg-rose-300",
      img: LandIcon,
    },
    {
      title: "Deptos",
      text: "25 propiedades",
      color: "bg-indigo-300",
      img: EdfIcon,
    },
    {
      title: "Locales",
      text: "25 propiedades",
      color: "bg-green-300",
      img: StoreIcon,
    },
  ];