import React from "react";
import BrochureCard from "./pureComponentes/BrochureCard";
import { dataButtonsBrochure } from "../data/dataBrochure";

function Brochure({title}) {
  return (
    <div className=" flex flex-col justify-center items-center w-full my-4 ">
      <h2 className="my-5 text-2xl">{title}</h2>
      <div className="flex gap-4 lg:w-[70%] flex-wrap justify-center items-center mx-5 ">
        {dataButtonsBrochure?.map((item, index) => (
          <BrochureCard key={index} item={item} />
        ))}
      </div>
    </div>
  );
}

export default Brochure;
