import React from 'react'
import ManagePropertyBanner from '../ManagePropertyBanner'
import { FaEye, FaRegCheckCircle } from "react-icons/fa";
import { GrSecure } from "react-icons/gr";
import AOS from "aos";
import "aos/dist/aos.css";
import imgBack from '../../assets/horizontal/logoHN.png'
function Admin() {
  AOS.init();

  const features = [
    {
      name: 'Visibilidad',
      description:
        'Aumenta la exposición de tus propiedades con estrategias de marketing efectivas y presencia en múltiples plataformas digitales.',
      icon: FaEye, // Reemplaza con el ícono que desees
    },
    {
      name: 'Seguridad',
      description:
        'Garantizamos la seguridad de tus datos y propiedades a través de procesos rigurosos y tecnologías avanzadas.',
      icon: GrSecure,
    },
    {
      name: 'Calidad',
      description:
        'Ofrecemos un servicio de alta calidad, asegurando que cada detalle sea gestionado con la mayor atención y profesionalismo.',
      icon: FaRegCheckCircle, // Reemplaza con el ícono que desees
    },
  ];

  return (
    <section className='container'>
      <ManagePropertyBanner />
      <div data-aos="zoom-out"
        data-aos-duration="1500" className="overflow-hidden bg-white py-14 sm:py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">
            <div className="lg:pr-4 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-2xl font-semibold leading-7 text-orange-500">JDYC</h2>
                <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Negocios inmobiliarios</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Ofrecemos servicios especializados para la gestión integral de propiedades, brindando soluciones personalizadas y eficientes a nuestros clientes.
                </p>

                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-orange-600" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              alt="Product screenshot"
              src={imgBack}
              width={2432}
              height={1442}
              className="w-[48rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-0 lg:-ml-0"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Admin