import React from "react";
import { Link } from "react-router-dom";

function NotFound404() {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center p-6">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <img
          src="https://source.unsplash.com/random/400x200?error"
          alt="Not Found"
          className="w-full object-cover object-center"
        />
        <div className="mt-6 text-center">
          <h1 className="text-2xl font-semibold text-gray-800">
            404 - Página no encontrada
          </h1>
          <p className="mt-2 text-gray-600">
            Lo sentimos, la página que estás buscando no existe.
          </p>
          <Link
            to="/"
            className="mt-4 inline-block bg-orange-500 text-white px-6 py-2 rounded-md hover:bg-orange-600"
          >
            Volver al inicio
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound404;
