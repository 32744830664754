import { useEffect, useState } from "react";
import { supabase } from "../supabaseClient"; // Asegúrate de tener esta configuración de cliente
import { toast, ToastContainer } from "react-toastify";
import LoadingModal from "./pureComponentes/LoadingModal";
import img0001 from "../assets/admin/inmo001.png";
import img0002 from "../assets/admin/inmo002.png";
import img0003 from "../assets/admin/inmo003.png";
import img0004 from "../assets/admin/inmo004.png";
import img0005 from "../assets/admin/inmo005.png";

function UpdateInfo() {

  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        setUserId(user.id);
        console.log(user);
      } else {
        console.log("No hay usuario logueado");
        setUserId("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, [userId]);

  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [tituloWeb, setTituloWeb] = useState({});
  const [propuestaWeb, setPropuestaWeb] = useState({});
  const [funcionalidadesWeb, setFuncionalidadesWeb] = useState({});
  const [adminWeb, setAdminWeb] = useState({});
  const [faq, setFAQ] = useState({});

  const fetchData = async () => {
    try {
      const { data, error } = await supabase.from("content-web").select("*");
      if (error) throw error;
      setData(data);
      console.log(data);

      if (data) {
        setTituloWeb(data.find((item) => item.content === "titulo-web"));
        setPropuestaWeb(data.find((item) => item.content === "propuesta-web"));
        setFuncionalidadesWeb(
          data.find((item) => item.content === "funcionalidades-web")
        );
        setAdminWeb(data.find((item) => item.content === "administracion-web"));
        setFAQ(data.find((item) => item.content === "faqs"));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateTituloWeb = async (id) => {
    setIsLoading(true);
    try {
      // Realiza el update de la información de 'field01' para el elemento con el ID proporcionado
      await supabase
        .from("content-web")
        .update({
          field01: tituloWeb.field01, // Actualiza el campo 'field01' con el nuevo contenido
        })
        .eq("id", id);

      toast.success("Datos actualizados correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);
    } catch (error) {
      console.error("Error actualizando el título:", error);
      setIsLoading(false);
    }
  };

  const handleUpdatePropuestaWeb = async (id) => {
    setIsLoading(true)
    try {

      // Realiza el update de la información de 'field01', 'field02' y 'field03' para el elemento con el ID proporcionado
      await supabase
        .from("content-web")
        .update({
          field01: propuestaWeb.field01, // Actualiza el campo 'field01' con el nuevo contenido
          field02: propuestaWeb.field02, // Actualiza el campo 'field02' con el nuevo contenido
          field03: propuestaWeb.field03, // Actualiza el campo 'field03' con el nuevo contenido
        })
        .eq("id", id);

      toast.success("Datos actualizados correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);


    } catch (error) {
      console.error("Error actualizando la propuesta:", error);
      setIsLoading(false);
    }
  };

  const handleUpdateFuncionalidadesWeb = async (id) => {
    setIsLoading(true)
    try {

      // Realiza el update de la información de 'field01' y 'field02' para el elemento con el ID proporcionado
      await supabase
        .from("content-web")
        .update({
          field01: funcionalidadesWeb.field01, // Actualiza el campo 'field01' con el nuevo contenido
          field02: funcionalidadesWeb.field02, // Actualiza el campo 'field02' con el nuevo contenido
        })
        .eq("id", id);

      toast.success("Datos actualizados correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);


    } catch (error) {
      console.error("Error actualizando las funcionalidades:", error);
      setIsLoading(false);
    }
  };

  const handleUpdateAdminWeb = async (id) => {
    setIsLoading(true)
    try {
      // Realiza el update de la información de 'field01' para el elemento con el ID proporcionado
      await supabase
        .from("content-web")
        .update({
          field01: adminWeb.field01, // Actualiza el campo 'field01' con el nuevo contenido
        })
        .eq("id", id);

      toast.success("Datos actualizados correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);
    } catch (error) {
      console.error("Error actualizando la administración:", error);
      setIsLoading(false);
    }
  };

  const handleUpdateFAQ = async (id) => {
    setIsLoading(true)
    try {
      // Realiza el update de la información de 'field01', 'field02', 'field03' y 'field04' para el elemento con el ID proporcionado
      await supabase
        .from("content-web")
        .update({
          field01: faq.field01, // Actualiza el campo 'field01' con el nuevo contenido
          field02: faq.field02, // Actualiza el campo 'field02' con el nuevo contenido
          field03: faq.field03, // Actualiza el campo 'field03' con el nuevo contenido
          field04: faq.field04, // Actualiza el campo 'field04' con el nuevo contenido
        })
        .eq("id", id);

      toast.success("Datos actualizados correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);
    } catch (error) {
      console.error("Error actualizando la FAQ:", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-2">Secciones web</h1>
      <div className="w-full border rounded-lg min-h-40 flex p-4 mb-4">
        <div className="w-[50%] h-40">
          <h2 className="font-semibold">Titulo sitio web</h2>
          <div>
            <label className="font-light text-center">Contenido</label>
            <textarea
              type="text"
              name="titulo"
              value={tituloWeb?.field01?.content || ""}
              onChange={(e) =>
                setTituloWeb((prev) => ({
                  ...prev,
                  field01: {
                    ...prev?.field01,
                    content: e.target.value,
                  },
                }))
              }
              className="w-full px-4 py-2 border rounded h-full"
            />
          </div>
          <div className="w-full flex justify-end">
            <button
              onClick={() => handleUpdateTituloWeb(tituloWeb.id)}
              className="w-fit py-2 px-4 bg-orange-500 text-white font-bold rounded hover:bg-teal-600"
            >
              Actualizar
            </button>
          </div>
        </div>
        <div className="w-[50%] h-full pl-4">
          <p className="font-light text-center">Referencia</p>
          <img src={img0001} alt="img-referencial" />
        </div>
      </div>

      <div className="w-full border rounded-lg min-h-40 flex p-4 mb-4">
        <div className="w-[50%]">
          <h2 className="font-semibold">Propuesta web</h2>
          <div>
            <label className="font-light text-center">
              Publica tu propiedad
            </label>
            <textarea
              type="text"
              name="titulo"
              value={propuestaWeb?.field01?.content || ""}
              onChange={(e)=>{
                setPropuestaWeb((prev) => ({
                 ...prev,
                  field01: {
                   ...prev?.field01,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full text-light"
            />
          </div>
          <div>
            <label className="font-light text-center">Fácil y seguro</label>
            <textarea
              type="text"
              name="titulo"
              value={propuestaWeb?.field02?.content || ""}
              onChange={(e)=>{
                setPropuestaWeb((prev) => ({
                 ...prev,
                  field02: {
                   ...prev?.field02,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full text-light"
            />
          </div>
          <div>
            <label className="font-light text-center">Sin intermediarios</label>
            <textarea
              type="text"
              name="titulo"
              value={propuestaWeb?.field03?.content || ""}
              onChange={(e)=>{
                setPropuestaWeb((prev) => ({
                 ...prev,
                  field03: {
                   ...prev?.field03,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full text-light"
            />
          </div>
          <div className="w-full flex justify-end">
            <button onClick={()=> handleUpdatePropuestaWeb(propuestaWeb.id) } className="w-fit py-2 px-4 bg-orange-500 text-white font-bold rounded hover:bg-teal-600">
              Actualizar
            </button>
          </div>
        </div>
        <div className="w-[50%] h-full pl-4">
          <p className="font-light text-center">Referencia</p>
          <img src={img0002} alt="img-referencial" />
        </div>
      </div>

      <div className="w-full border rounded-lg min-h-40 flex p-4 mb-4">
        <div className="w-[50%]">
          <h2 className="font-semibold">Funcionalidades web</h2>
          <div>
            <label className="font-light text-center">Parrafo #1</label>
            <textarea
              type="text"
              name="titulo"
              value={funcionalidadesWeb?.field01?.content || ""}
              onChange={(e)=> {
                setFuncionalidadesWeb((prev) => ({
                 ...prev,
                  field01: {
                   ...prev?.field01,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
          </div>
          <div>
            <label className="font-light text-center">Parrafo #2</label>
            <textarea
              type="text"
              name="titulo"
              value={funcionalidadesWeb?.field02?.content || ""}
              onChange={(e)=>{
                setFuncionalidadesWeb((prev) => ({
                 ...prev,
                  field02: {
                   ...prev?.field02,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
          </div>
          <div className="w-full flex justify-end">
            <button onClick={()=> handleUpdateFuncionalidadesWeb(funcionalidadesWeb.id)} className="w-fit py-2 px-4 bg-orange-500 text-white font-bold rounded hover:bg-teal-600">
              Actualizar
            </button>
          </div>
        </div>
        <div className="w-[50%] h-full pl-4">
          <p className="font-light text-center">Referencia</p>
          <img src={img0003} alt="img-referencial" />
        </div>
      </div>

      <div className="w-full border rounded-lg min-h-40 flex p-4 mb-4">
        <div className="w-[50%] h-40">
          <h2 className="font-semibold">Administración de propiedades web</h2>
          <div>
            <label className="font-light text-center">Texto</label>
            <textarea
              type="text"
              name="titulo"
              value={adminWeb?.field01?.content || ""}
              onChange={(e)=>{
                setAdminWeb((prev) => ({
                 ...prev,
                  field01: {
                   ...prev?.field01,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
          </div>
          <div className="w-full flex justify-end">
            <button onClick={()=> handleUpdateAdminWeb(adminWeb.id)} className="w-fit py-2 px-4 bg-orange-500 text-white font-bold rounded hover:bg-teal-600">
              Actualizar
            </button>
          </div>
        </div>
        <div className="w-[50%] h-full pl-4">
          <p className="font-light text-center">Referencia</p>
          <img src={img0004} alt="img-referencial" />
        </div>
      </div>

      <div className="w-full border rounded-lg min-h-40 flex p-4 mb-4">
        <div className="w-[50%]">
          <h2 className="font-semibold">FAQs web</h2>
          <div className="border my-2"></div>
          <div>
            <label className="font-light text-center">Pregunta #1</label>
            <input
              type="text"
              name="titulo"
              value={faq?.field01?.title || ""}
              onChange={(e)=>{
                setFAQ((prev) => ({
                 ...prev,
                  field01: {
                   ...prev?.field01,
                    title: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
            <label className="font-light text-center">Respuesta #1</label>
            <textarea
              type="text"
              name="titulo"
              value={faq?.field01?.content || ""}
              onChange={(e)=>{
                setFAQ((prev) => ({
                 ...prev,
                  field01: {
                   ...prev?.field01,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
          </div>

          <div className="border my-2"></div>

          <div>
            <label className="font-light text-center">Pregunta #2</label>
            <input
              type="text"
              name="titulo"
              value={faq?.field02?.title || ""}
              onChange={(e) =>{
                setFAQ((prev) => ({
                 ...prev,
                  field02: {
                   ...prev?.field02,
                    title: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
            <label className="font-light text-center">Respuesta #2</label>
            <textarea
              type="text"
              name="titulo"
              value={faq?.field02?.content || ""}
              onChange={(e)=>{
                setFAQ((prev) => ({
                 ...prev,
                  field02: {
                   ...prev?.field02,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
          </div>
          <div className="border my-2"></div>
          <div>
            <label className="font-light text-center">Pregunta #3</label>
            <input
              type="text"
              name="titulo"
              value={faq?.field03?.title || ""}
              onChange={(e)=>{
                setFAQ((prev) => ({
                 ...prev,
                  field03: {
                   ...prev?.field03,
                    title: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
            <label className="font-light text-center">Respuesta #3</label>
            <textarea
              type="text"
              name="titulo"
              value={faq?.field03?.content || ""}
              onChange={(e)=>{
                setFAQ((prev) => ({
                 ...prev,
                  field03: {
                   ...prev?.field03,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
          </div>
          <div className="border my-2"></div>
          <div>
            <label className="font-light text-center">Pregunta #4</label>
            <input
              type="text"
              name="titulo"
              value={faq?.field04?.title || ""}
              onChange={(e)=>{
                setFAQ((prev) => ({
                 ...prev,
                  field04: {
                   ...prev?.field04,
                    title: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
            <label className="font-light text-center">Respuesta #4</label>
            <textarea
              type="text"
              name="titulo"
              value={faq?.field04?.content || ""}
              onChange={(e)=>{
                setFAQ((prev) => ({
                 ...prev,
                  field04: {
                   ...prev?.field04,
                    content: e.target.value,
                  },
                }))
              }}
              className="w-full px-4 py-2 border rounded h-full"
            />
          </div>
          <div className="w-full flex justify-end">
            <button onClick={()=> handleUpdateFAQ(faq.id)} className="w-fit py-2 px-4 bg-orange-500 text-white font-bold rounded hover:bg-teal-600">
              Actualizar
            </button>
          </div>
        </div>
        <div className="w-[50%] h-full pl-4">
          <p className="font-light text-center">Referencia</p>
          <img src={img0005} alt="img-referencial" />
        </div>
      </div>

      <ToastContainer />
      {isLoading && <LoadingModal title={"Actualizando datos"} />}
    </div>
  );
}

export default UpdateInfo;
