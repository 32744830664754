import React from 'react'
import { CiLocationArrow1 } from "react-icons/ci";
import { GrSecure } from "react-icons/gr";
import { HiArrowPath } from "react-icons/hi2";
import { IoIosFingerPrint } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
function FAQs2() {
    AOS.init();
    const features = [
        {
          name: '¿Cómo se determina el valor de una propiedad en el proceso de tasación?',
          description:
            'El valor de una propiedad se determina mediante un análisis detallado que incluye factores como la ubicación, tamaño, estado de conservación, y comparables en el mercado. Nuestro equipo de tasadores expertos realiza una inspección minuciosa para garantizar una evaluación precisa.',
          icon: CiLocationArrow1,
        },
        {
          name: '¿Qué documentos necesito proporcionar para una tasación?',
          description:
            'Para realizar una tasación, es necesario presentar documentos como la escritura de la propiedad, planos, recibos de impuestos y servicios, y cualquier otro documento relevante. Nuestro equipo te guiará en la recopilación de toda la documentación necesaria.',
          icon: GrSecure,
        },
        {
          name: '¿Cuánto tiempo toma completar una tasación?',
          description:
            'El tiempo para completar una tasación puede variar, pero en general, el proceso toma entre 48 y 72 horas. Nuestro equipo se esfuerza por ofrecer resultados precisos en el menor tiempo posible, sin comprometer la calidad del servicio.',
          icon: HiArrowPath,
        },
        {
          name: '¿Cuál es la importancia de realizar una tasación antes de vender o comprar una propiedad?',
          description:
            'Una tasación es crucial para determinar el valor justo de mercado de una propiedad, lo que ayuda a establecer un precio de venta adecuado o a evitar pagar de más al comprar. Proporciona una base sólida para negociaciones y decisiones financieras informadas.',
          icon: IoIosFingerPrint,
        },
      ]
      
    return (
        <div data-aos="zoom-out"
        data-aos-duration="1500" className="pt-20 pb-20 border-t">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
  
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Preguntas frecuentes
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-12">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-2 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-500">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-sm leading-7 text-gray-600">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
}

export default FAQs2