import React from 'react'
// import Digital from '../assets/partners/digitalocean-2.svg';
// import MercadoPago from '../assets/partners/mercadopago.svg'
// import Facebook from '../assets/partners/facebook-ads.svg'
// import Google from '../assets/partners/google-mi-negocio.svg';
// import Adwords from '../assets/partners/google-adwords.svg'
import '../styles/Partners.css'
import AOS from "aos";
import "aos/dist/aos.css";


export default function Partners() {
    AOS.init();

    const arrayPartnerts = [
        {
            id:"paludo",
            src:"https://paludo.com.br/assets/site/img/logo_2024.svg",
            alt:"Paludo Logo"
        },
        // {
        //     id:"MercadoPago",
        //     src:MercadoPago,
        //     alt:"MercadoPago Logo"
        // },
        // {
        //     id:"Facebook",
        //     src:Facebook,
        //     alt:"Facebook Logo"
        // },
        // {
        //     id:"Google",
        //     src:Google,
        //     alt:"Google Logo"
        // },
        // {
        //     id:"Adwords",
        //     src:Adwords,
        //     alt:"Adwords Logo"
        // },

    ]
  return (
    <div
    data-aos="flip-left"
    data-aos-duration="2000" 
    
     className='div-partners container-fluid mb-20 '
     >
        <h2 className='title-partners text-4xl text-gray-700 mb-6'>Partners</h2>
        <div className='div-img-partners'>
            {
                arrayPartnerts.map((item)=>{
                    return (
                        <img key={item.id} src={item.src} alt={item.alt} className='logo-partners' />
                    )
                })
            }
        </div>
    </div>
  )
}