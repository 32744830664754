import React from "react";
import { CiLocationArrow1 } from "react-icons/ci";
import { GrSecure } from "react-icons/gr";
import { HiArrowPath } from "react-icons/hi2";
import { IoIosFingerPrint } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
function FAQs({props}) {
  AOS.init();

  const features = [
    {
      name: props?.field01?.title,
      description:props?.field01?.content,
      icon: CiLocationArrow1,
    },
    {
      name: props?.field02?.title,
      description:props?.field02?.content,
      icon: GrSecure,
    },
    {
      name: props?.field03?.title,
      description:props?.field03?.content,
      icon: HiArrowPath,
    },
    {
      name: props?.field04?.title,
      description:props?.field04?.content,
      icon: IoIosFingerPrint,
    },
  ]
  return (

    <div data-aos="zoom-out"
      data-aos-duration="1500" className="pt-8 pb-20 border-t">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">

          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Preguntas frecuentes
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-12">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-2 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-500">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-sm leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default FAQs;
