import React, { useState, useEffect } from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import iconLogo from '../../assets/vertical/LogoVNyB.png'
import LogoSVG from "../../assets/horizontal/logoHN.svg";
import { supabase } from '../../supabaseClient';
import { ArrowRightEndOnRectangleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { toast, ToastContainer } from 'react-toastify';
import AddProperties from '../AddProperties';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import UpdateInfo from '../UpdateInfo';


function RealStateAdmin() {
    const [properties, setProperties] = useState([])
    const [reload, setReload] = useState(true)
    const [page, setPage] = useState('propiedades')
  
    const navigation = [
        { name: 'Propiedades', current: true, value: "propiedades" },
        { name: 'Agregar propiedades', current: false, value: "agregar" },
        {name: 'Contenido web', current: false, value : 'admin'}
    ]


    async function logout() {
        const response = await supabase.auth.signOut()

        if (response) {
            console.log('Logged out')
            toast.success('Cierre de sesión con exito', 'success');
            setTimeout(() => {
                window.location.href = '/login';
            }, 1500)

            return;
        }
    }

    const fetchData = async () => {
        await supabase.from('properties').select('*').then((response) => {
         //   console.log(response.data)
            setProperties(response.data)
        }).catch((err) => {
            console.error(err)
        })
    }

    useEffect(() => {
        fetchData()
    }, [reload, page])


    // const deleteProperty = async (id) => {
    //     try {
    //       // Llamada a Supabase para eliminar un elemento por su ID
    //       const { data, error } = await supabase
    //         .from('properties')
    //         .delete()
    //         .eq('id', id); // Borrar donde el 'id' coincida
      
    //       if (error) {
    //         console.error('Error al eliminar la propiedad:', error.message);
    //         toast.error('Error al eliminar la propiedad');
    //       } else {
    //         setReload(!reload)
    //         toast.success('Propiedad eliminada exitosamente');
    //         console.log('Propiedad eliminada:', data);
    //       }
    //     } catch (error) {
    //       console.error('Error al procesar la eliminación:', error.message);
    //       toast.error('Error inesperado al eliminar la propiedad');
    //     }
    //   };

      const deleteProperty = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
    
        if (!isConfirmed) {
            return;
        }
    
        try {
            const { data, error } = await supabase
                .from('properties')
                .delete()
                .eq('id', id);
    
            if (error) {
                console.error('Error al eliminar la propiedad:', error.message);
                toast.error('Error al eliminar la propiedad');
            } else {
                setReload(!reload);
                toast.success('Propiedad eliminada exitosamente');
                console.log('Propiedad eliminada:', data);
            }
        } catch (error) {
            console.error('Error al procesar la eliminación:', error.message);
            toast.error('Error inesperado al eliminar la propiedad');
        }
    };



    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className="min-h-full">
            <Disclosure as="nav" className="bg-gray-50 border-b">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <img
                                    alt="Your Company"
                                    src={LogoSVG}
                                    className=" w-full h-20 "
                                />
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    {navigation.map((item) => (
                                        <div
                                            key={item.name}
                                            onClick={() => setPage(item.value)}

                                            className={classNames(
                                                item.value === page ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                                            )}
                                        >
                                            {item.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <div className=" flex items-center">

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="absolute -inset-1.5" />
                                            <img alt="" src={iconLogo} className="h-12 w-12 rounded-full" />
                                        </MenuButton>
                                    </div>
                                </Menu>
                                <div className='w-10 ml-4' onClick={logout} >
                                    <ArrowRightEndOnRectangleIcon className='cursor-pointer' />
                                </div>

                            </div>

                        </div>
                        <div className="-mr-2 flex md:hidden">
                            {/* Mobile menu button */}
                            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="absolute -inset-0.5" />
                                <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                                <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                            </DisclosureButton>
                        </div>
                    </div>
                </div>

                <DisclosurePanel className="md:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                        {navigation.map((item) => (
                            <DisclosureButton
                                key={item.name}
                                onClick={() => setPage(item.value)}
                                className={classNames(
                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'block rounded-md px-3 py-2 text-base font-medium',
                                )}
                            >
                                {item.name}
                            </DisclosureButton>
                        ))}
                    </div>
                </DisclosurePanel>
            </Disclosure>

            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-2xl font-bold tracking-tight text-gray-900">Gestión JDYC Negocios Inmobiliarios</h1>
                </div>
            </header>

            <main>
                {
                    page === 'propiedades' ? (
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 min-h-screen ">
                            <table className='w-full font-light' >
                                <thead className='border-b border-gray-400' >
                                    <tr >
                                        <th>ID</th>
                                        <th>Ciudad</th>
                                        <th>Dirección</th>
                                        <th>Precio</th>
                                        <th>Moneda</th>
                                        <th>Detalle</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center' >
                                    {/* Fetch and display data from your database */}
                                    {
                                        properties?.map((item, index) => {
                                            return (
                                                <tr key={index} className='border-b '>
                                                    <td>{item.id}</td>
                                                    <td>{item.ciudad}</td>
                                                    <td>{item.direccion_calle}</td>
                                                    <td>{item.precio}</td>
                                                    <td>{item.moneda}</td>
                                                    <td className='flex gap-2 justify-center items-center mt-1  ' >
                                                        <Link className='w-4 h-4 text-indigo-600' to={`/admin/property/${item.id}`}  ><MdOutlineRemoveRedEye /></Link>
                                                        <button className='w-4 h-4 text-red-300' onClick={() => deleteProperty(item.id)} ><TrashIcon /></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                        </div>
                    ) : page === 'agregar' ? (
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 min-h-screen ">
                            <AddProperties/>

                        </div>
                    ) : (
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 min-h-screen ">
                            <UpdateInfo/>
                        </div>
                    )
                }

            </main>
            <ToastContainer />
        </div>
    )
}

export default RealStateAdmin