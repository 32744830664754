import React, { useState, useEffect } from 'react'
import { Disclosure, DisclosureButton } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import LogoSVG from "../../assets/horizontal/logoHN.svg";
import { supabase } from '../../supabaseClient';
import { toast, ToastContainer } from 'react-toastify';
import { MdArrowBackIos } from "react-icons/md";
import { Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'; // Para generar IDs únicos para las imágenes
import LoadingModal from '../pureComponentes/LoadingModal';
function DetailPropertyAdmin() {
    const [userId, setUserId] = useState('')
    const { id } = useParams();
    const [imgOne, setImgOne] = useState(null)
    const [imgTwo, setImgTwo] = useState(null)
    const [imgThree, setImgThree] = useState(null)

    const [imgPropOne, setImgPropOne] = useState(null)
    const [imgPropTwo, setImgPropTwo] = useState(null)
    const [imgPropThree, setImgPropThree] = useState(null)

    const [formData, setFormData] = useState({})
    const provinces = [
        "Buenos Aires", "Córdoba", "Santa Fe", "Mendoza", "Tucumán", "Salta", "Jujuy",
        "San Luis", "San Juan", "La Pampa", "Neuquén", "Río Negro", "Chubut", "Santa Cruz",
        "Tierra del Fuego", "Misiones", "Corrientes", "Entre Ríos", "Formosa", "Chaco",
        "La Rioja", "Catamarca", "Santiago del Estero"
    ];

    const [isLoadingImage, setIsLoadingImage] = useState(false)

    const getUser = async () => {

        try {
            const { data: { user } } = await supabase.auth.getUser()
            if (user !== null) {
                setUserId(user.id);
                console.log(user)
            } else {
                console.log('No hay usuario logueado');
                setUserId('')
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getUser()
    }, [userId])

    const fetchDataPropertie = async () => {
        try {
            const { data: properties } = await supabase.from('properties').select('*').eq('id', parseInt(id));
            setFormData(properties[0]);
            setImgPropOne(properties[0].img01)
            setImgPropTwo(properties[0].img02)
            setImgPropThree(properties[0].img03)
            console.log(properties[0])
        } catch (error) {
            console.error(error);
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        fetchDataPropertie();
    }, [id])

    const handleUpdateProp = async (e) => {
        e.preventDefault();

        try {
            await supabase.from('properties').update({ ...formData }).eq('id', parseInt(id))

            toast.success('Propiedad actualizada correctamente', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } catch (error) {

            console.log(error)
            console.log('Error al intentar actualizar')

        }




    }

    const uploadImageOne = async () => {
        const idPropertie = id
        const imagePresent = imgPropOne;
        let file = imgOne
        const fileName = `${userId}/${uuidv4()}_${file.name}`; // Nombre único
        setIsLoadingImage(true)

        if (imagePresent) {
            const { error: deleteError } = await supabase.storage.from('jdyc').remove([imagePresent]);

            if (deleteError) {
                console.error('Error al eliminar la imagen existente:', deleteError.message);
                toast.error('Error al eliminar la imagen existente');
                setIsLoadingImage(false);
                return; // Salir de la función si hay un error al eliminar
            }
        }

        const { data, error } = await supabase.storage.from('jdyc').upload(fileName, file);

        if (data) {
            toast.success('Imagen subida con éxito', 'success');
            const imagePath = data.fullPath; // O data.fullPath, dependiendo de cómo esté configurado tu storage

            // Actualizar el campo img01 de la propiedad en la base de datos
            const { error: updateError } = await supabase
                .from('properties')
                .update({ img01: imagePath }) // Actualiza el campo img01
                .eq('id', idPropertie); // Asegúrate de que estás actualizando la propiedad correcta
            setIsLoadingImage(false)
            if (updateError) {
                console.error('Error al actualizar la propiedad:', updateError.message);
                toast.error('Error al actualizar la propiedad');
                setIsLoadingImage(false)
                // throw new Error('Error al actualizar la propiedad');
            } else {
                console.log('Campo img01 actualizado con éxito');
                setIsLoadingImage(false)
            }

            return imagePath; // Retorna el path de la imagen
        } else {
            console.error('Error al subir la imagen:', error.message);
            toast.error('Error al subir la imagen');
            setIsLoadingImage(false)
            // throw new Error('Error al subir la imagen');
        }
    };
    const uploadImageTwo = async () => {
        const idPropertie = id; // ID de la propiedad
        let file = imgTwo; // Archivo de imagen a subir
        const fileName = `${userId}/${uuidv4()}_${file.name}`; // Nombre único para el archivo

        setIsLoadingImage(true)
        // Subir la imagen al almacenamiento
        const { data, error } = await supabase.storage.from('jdyc').upload(fileName, file);

        if (data) {
            toast.success('Imagen subida con éxito', 'success');
            const imagePath = data.fullPath; // O data.fullPath, dependiendo de cómo esté configurado tu storage

            // Actualizar el campo img01 de la propiedad en la base de datos
            const { error: updateError } = await supabase
                .from('properties')
                .update({ img02: imagePath }) // Actualiza el campo img01
                .eq('id', idPropertie); // Asegúrate de que estás actualizando la propiedad correcta

            if (updateError) {
                console.error('Error al actualizar la propiedad:', updateError.message);
                toast.error('Error al actualizar la propiedad');
                setIsLoadingImage(false)
                throw new Error('Error al actualizar la propiedad');
            } else {
                console.log('Campo img01 actualizado con éxito');
                setIsLoadingImage(false)
            }

            return imagePath; // Retorna el path de la imagen
        } else {
            console.error('Error al subir la imagen:', error.message);
            toast.error('Error al subir la imagen');
            setIsLoadingImage(false)
            throw new Error('Error al subir la imagen');
        }
    };
    const uploadImageThree = async () => {
        const idPropertie = id; // ID de la propiedad
        let file = imgThree; // Archivo de imagen a subir
        const fileName = `${userId}/${uuidv4()}_${file.name}`; // Nombre único para el archivo

        setIsLoadingImage(true)
        // Subir la imagen al almacenamiento
        const { data, error } = await supabase.storage.from('jdyc').upload(fileName, file);
        if (data) {
            toast.success('Imagen subida con éxito', 'success');
            const imagePath = data.fullPath; // O data.fullPath, dependiendo de cómo esté configurado tu storage
            // Actualizar el campo img01 de la propiedad en la base de datos
            const { error: updateError } = await supabase
                .from('properties')
                .update({ img03: imagePath }) // Actualiza el campo img01
                .eq('id', idPropertie); // Asegúrate de que estás actualizando la propiedad correcta

            if (updateError) {
                console.error('Error al actualizar la propiedad:', updateError.message);
                toast.error('Error al actualizar la propiedad');
                setIsLoadingImage(false)
                throw new Error('Error al actualizar la propiedad');
            } else {
                console.log('Campo img01 actualizado con éxito');
                setIsLoadingImage(false)
            }
            return imagePath; // Retorna el path de la imagen

        } else {
            console.error('Error al subir la imagen:', error.message);
            toast.error('Error al subir la imagen');
            setIsLoadingImage(false)
            throw new Error('Error al subir la imagen');

        }
    }

    const handleSelectImgOne = (e) => {
        setImgPropOne(null)
        setImgOne(e.target.files[0]);

    }
    const handleSelectImgTwo = (e) => {
        setImgPropTwo(null)
        setImgTwo(e.target.files[0]);

    }
    const handleSelectImgThree = (e) => {
        setImgPropThree(null)
        setImgThree(e.target.files[0]);

    }

    return (
        <div className="min-h-full">
            <Disclosure as="nav" className="bg-gray-50 border-b">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                        <div className="flex w-screen items-center justify-between ">
                            <div className="flex-shrink-0">
                                <img
                                    alt="Your Company"
                                    src={LogoSVG}
                                    className=" w-full h-20 "
                                />
                            </div>
                            <span className="inline-flex items-center border px-2 rounded-md  bg-[#EB6E3C]" >
                                <MdArrowBackIos className="text-gray-50" />
                                <Link className="px-4 py-1 text-gray-50 " to={'/admin/dashboard'} >
                                    Atras
                                </Link>
                            </span>

                        </div>

                        <div className="-mr-2 flex md:hidden">
                            {/* Mobile menu button */}
                            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="absolute -inset-0.5" />
                                <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                                <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                            </DisclosureButton>
                        </div>
                    </div>
                </div>


            </Disclosure>

            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-2xl font-bold tracking-tight text-gray-900">Gestión JDYC Negocios Inmobiliarios</h1>
                    
                </div>
            </header>

            <main className='my-auto mb-20'>
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">

                    <form
                        onSubmit={handleUpdateProp}
                        className="space-y-4">
                        {/* Campo de título */}
                        <div>
                            <label className="block mb-2 font-semibold">Título</label>
                            <input
                                type="text"
                                name="titulo"
                                value={formData.titulo}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border rounded"
                                required
                            />
                        </div>

                        {/* Dirección */}
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block mb-2 font-semibold">Calle</label>
                                <input
                                    type="text"
                                    name="direccion_calle"
                                    value={formData.direccion_calle}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Número</label>
                                <input
                                    type="number"
                                    name="direccion_numero"
                                    value={formData.direccion_numero}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block mb-2 font-semibold">Referencia</label>
                            <input
                                type="text"
                                name="direccion_referencia"
                                value={formData.direccion_referencia}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border rounded"
                            />
                        </div>

                        {/* Provincia */}
                        <div>
                            <label className="block mb-2 font-semibold">Provincia</label>
                            <select
                                name="provincia"
                                value={formData.provincia}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border rounded"
                                required
                            >
                                <option value="">Selecciona una provincia</option>
                                {provinces.map((province) => (
                                    <option key={province} value={province}>
                                        {province}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Ciudad y Barrio */}
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block mb-2 font-semibold">Ciudad</label>
                                <input
                                    type="text"
                                    name="ciudad"
                                    value={formData.ciudad}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Barrio</label>
                                <input
                                    type="text"
                                    name="barrio"
                                    value={formData.barrio}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                />
                            </div>
                        </div>

                        {/* Ambientes, Metros Techados, Metros Cuadrados */}
                        <div className="grid grid-cols-3 gap-4">
                            <div>
                                <label className="block mb-2 font-semibold">Ambientes</label>
                                <input
                                    type="number"
                                    name="ambientes"
                                    value={formData.ambientes}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Metros Techados</label>
                                <input
                                    type="number"
                                    name="metros_t"
                                    value={formData.metros_t}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Metros Cuadrados</label>
                                <input
                                    type="number"
                                    name="metros_c"
                                    value={formData.metros_c}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                />
                            </div>
                        </div>

                        {/* Descripción corta y larga */}
                        <div>
                            <label className="block mb-2 font-semibold">Descripción Corta</label>
                            <input
                                type="text"
                                name="descripcion_corta"
                                value={formData.descripcion_corta}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border rounded"
                            />
                        </div>

                        <div>
                            <label className="block mb-2 font-semibold">Descripción Larga</label>
                            <textarea
                                name="descripcion_larga"
                                value={formData.descripcion_larga}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border rounded"
                                rows={5}
                                required
                            />
                        </div>

                        {/* Financiación, Precio y Moneda */}
                        <div className="grid grid-cols-3 gap-4">
                            <div>
                                <label className="block mb-2 font-semibold">Financiación</label>
                                <input
                                    type="text"
                                    name="financiacion"
                                    value={formData.financiacion}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Precio</label>
                                <input
                                    type="text"
                                    name="precio"
                                    value={formData.precio}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Moneda</label>
                                <select
                                    name="moneda"
                                    value={formData.moneda}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                >
                                    <option value="USD">$USD</option>
                                    <option value="ARS">$ARS</option>
                                </select>
                            </div>
                        </div>

                        {/* Tipo y Operación */}
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block mb-2 font-semibold">Tipo de Propiedad</label>
                                <select
                                    name="tipo"
                                    value={formData.tipo}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                >
                                    <option value="Lote">Lote</option>
                                    <option value="Casa">Casa</option>
                                    <option value="Depto">Depto</option>
                                    <option value="Local">Local</option>
                                    <option value="Turismo">Turismo</option>
                                    <option value="Oficinas">Oficinas</option>
                                    <option value="Industrial">Industrial</option>
                                </select>
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Operación</label>
                                <select
                                    name="operacion"
                                    value={formData.operacion}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                >
                                    <option value="Venta">Venta</option>
                                    <option value="Alquiler">Alquiler</option>
                                </select>
                            </div>
                        </div>

                        {/* Email y contacto */}
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block mb-2 font-semibold">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Contacto</label>
                                <input
                                    type="text"
                                    name="contacto"
                                    value={formData.contacto}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Telefono</label>
                                <input
                                    type="number"
                                    name="telefono"
                                    value={formData.telefono}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded"
                                    required
                                />
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded hover:bg-blue-600"
                        >
                            Actualizar
                        </button>
                    </form>
                </div>

                <div className=' my-4 h-200 flex w-full justify-center gap-10' >
                    <div className='border rounded-lg border-gray-400 flex flex-col items-center justify-center p-4 h-full'>
                        <h4 className=' text-xs'>Imagen Principal</h4>
                        <div className='w-[18rem] h-[18rem] flex flex-col justify-center items-center'>

                            {imgPropOne ? (
                                <img
                                    src={`https://ivevkdipktjjgbjnwtuc.supabase.co/storage/v1/object/public/${imgPropOne}`}
                                    alt='Imagen #1'
                                    className='object-contain w-full h-full rounded-md'
                                />
                            ) : imgOne ? (
                                <img
                                    src={URL.createObjectURL(imgOne)}
                                    alt='Imagen #1'
                                    className='object-contain w-full h-full rounded-md'
                                />
                            ) : (
                                <p className='text-gray-500'>No hay imagen</p>
                            )}

                            <div class="flex w-full h-screen items-center justify-center bg-grey-lighter">
                                <label class="w-16 flex flex-col items-center p-4 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue">
                                    <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                    </svg>                                  
                                    <input type='file' class="hidden" onChange={(e) => handleSelectImgOne(e)} accept="image/png, image/jpeg" />
                                </label>
                            </div>

                        </div>
                        <button disabled={!imgOne} onClick={uploadImageOne} className='btn bg-orange-400 py-1 px-4 text-white rounded-md mt-2'>Subir</button>
                    </div>

                    <div className='border rounded-lg border-gray-400 flex flex-col items-center justify-center p-4 h-full'>
                        <h4 className=' text-xs'>Imagen #2</h4>
                        <div className='w-[18rem] h-[18rem] flex flex-col justify-center items-center'>

                            {imgPropTwo ? (
                                <img
                                    src={`https://ivevkdipktjjgbjnwtuc.supabase.co/storage/v1/object/public/${imgPropTwo}`}
                                    alt='Imagen #1'
                                    className='object-contain w-full h-full rounded-md'
                                />
                            ) : imgTwo ? (
                                <img
                                    src={URL.createObjectURL(imgTwo)}
                                    alt='Imagen #1'
                                    className='object-contain w-full h-full rounded-md'
                                />
                            ) : (
                                <p className='text-gray-500'>No hay imagen</p>
                            )}

                            <div class="flex w-full h-screen items-center justify-center bg-grey-lighter">
                                <label class="w-16 flex flex-col items-center p-4 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue">
                                    <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                    </svg>                                  
                                    <input type='file' class="hidden" onChange={(e) => handleSelectImgTwo(e)} accept="image/png, image/jpeg" />
                                </label>
                            </div>

                        </div>
                        <button disabled={!imgTwo} onClick={uploadImageTwo} className='btn bg-orange-400 py-1 px-4 text-white rounded-md mt-2'>Subir</button>
                    </div>

                    <div className='border rounded-lg border-gray-400 flex flex-col items-center justify-center p-4 h-full'>
                        <h4 className=' text-xs'>Imagen #3</h4>
                        <div className='w-[18rem] h-[18rem] flex flex-col justify-center items-center'>

                            {imgPropThree ? (
                                <img
                                    src={`https://ivevkdipktjjgbjnwtuc.supabase.co/storage/v1/object/public/${imgPropThree}`}
                                    alt='Imagen #1'
                                    className='object-contain w-full h-full rounded-md'
                                />
                            ) : imgThree ? (
                                <img
                                    src={URL.createObjectURL(imgThree)}
                                    alt='Imagen #1'
                                    className='object-contain w-full h-full rounded-md'
                                />
                            ) : (
                                <p className='text-gray-500'>No hay imagen</p>
                            )}

                            <div class="flex w-full h-screen items-center justify-center bg-grey-lighter">
                                <label class="w-16 flex flex-col items-center p-4 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue">
                                    <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                    </svg>                                  
                                    <input type='file' class="hidden" onChange={(e) => handleSelectImgThree(e)} accept="image/png, image/jpeg" />
                                </label>
                            </div>

                        </div>
                        <button disabled={!imgThree} onClick={uploadImageThree} className='btn bg-orange-400 py-1 px-4 text-white rounded-md mt-2'>Subir</button>
                    </div>


                </div>

            </main>
            <ToastContainer />
            {
                isLoadingImage && <LoadingModal title={'Subiendo la imagen al servidor'} />
            }
        </div>
    )
}

export default DetailPropertyAdmin
