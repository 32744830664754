// import React from "react";
// import Navbar from "../Navbar";
// import Footer from "../Footer";
// import WhatsappContact from "../WhatsappContact";
// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom'

// function Layout({ children }) {
//   const { pathname } = useLocation()

//   useEffect(() => {
//       window.scrollTo(0,0)
//   }, [pathname])

//   return (
//     <main>
//       <Navbar />
//       {children}
//       <WhatsappContact/>
//       <Footer/>
//     </main>
//   );
// }

// export default Layout;

import React, { useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import WhatsappContact from "../WhatsappContact";
import { Outlet, useLocation } from 'react-router-dom';

function Layout() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main>
      <Navbar />
      {/* Aquí es donde se renderizan las rutas hijas */}
      <Outlet />
      <WhatsappContact />
      <Footer />
    </main>
  );
}

export default Layout;
