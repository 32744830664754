import ImgHero from '../assets/img/imgHero.png'
import AOS from "aos";
import "aos/dist/aos.css";
const Hero = ({props}) => {
 AOS.init();
  return (
    <section className=" max-w-screen-xl  mx-auto px-4 md:px-8">
      {/* <div className="absolute top-0 left-0 w-full h-full bg-white opacity-40"></div> */}
      <div className="gap-5 items-center lg:flex mt-5">
        <div 
        data-aos="fade-up" 
        data-aos-duration="1000" 
        className="flex-1 max-w-lg py-5 sm:mx-auto sm:text-center lg:max-w-max lg:text-left">
          <h1 className="text-3xl text-gray-800 font-semibold md:text-4xl">
            JDYC{" "}
            <span className="text-orange-500 block">Negocios Inmobiliarios</span>
          </h1>

          <p className="text-gray-500 leading-relaxed mt-3">
            {props?.field01?.content}
          </p>

          <a
            className="mt-5 px-6 py-3 text-gray-50 font-medium bg-[#EB6E3C] rounded-md inline-flex items-center"
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/5493547565707/?text=%20Hola,%20quisiera%20publicar%20mi%20propiedad%20"
          >
            Publica tu propiedad
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-1 duration-150"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          </a>
        </div>
        <div 
        data-aos="fade-left" 
        data-aos-duration="1000"   
        className="flex-1 mt-5 sm:w-9/12 lg:mt-0 lg:w-10">
          <img
            src={ImgHero}
            alt="hero-welcome"
            className="lg:h-[30vw] w-[100%]"
          />


        </div>
      </div>
    </section>
  );
};

export default Hero;
