// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom'

// function LayoutAdmin({ children }) {
//     const { pathname } = useLocation()
  
//     useEffect(() => {
//         window.scrollTo(0,0)
//     }, [pathname])
  
//     return (
//       <main>
//         {children}
//       </main>
//     )
// }

// export default LayoutAdmin

import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

function LayoutAdmin() {
    const { pathname } = useLocation();
  
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
  
    return (
      <main>
        {/* Aquí se renderizan las rutas hijas */}
        <Outlet />
      </main>
    );
}

export default LayoutAdmin;
