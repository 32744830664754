import React, { useEffect, useState } from "react";
function AnimatedNumber({ number, delay }) {
    const [displayedNumber, setDisplayedNumber] = useState(0);

    useEffect(() => {
      if (number > 0) {
        const interval = setInterval(() => {
          setDisplayedNumber((prev) => {
            if (prev < number) {
              return prev + 1;
            } else {
              clearInterval(interval);
              return number;
            }
          });
          
        }, delay); // Ajusta la velocidad de la animación según sea necesario
  
        return () => clearInterval(interval);
      } else {
        setDisplayedNumber(0);
      }
      // eslint-disable-next-line
    }, [number]);
  
    return <span className="font-heading text-[2.6rem] font-bold  lg:text-5xl xl:text-6xl">{displayedNumber}</span>;
}
// function AnimatedNumber({ number, delay }) {
//     const [displayedNumber, setDisplayedNumber] = useState(0);
//     const [hasAnimated, setHasAnimated] = useState(false);
//     const ref = useRef(null);

//     useEffect(() => {

//         let current = ref.current
//         const observer = new IntersectionObserver(
//             ([entry]) => {
//                 if (entry.isIntersecting && !hasAnimated) {
//                     setHasAnimated(true);
//                 }
//             },
//             { threshold: 0.1 } // Cambia este valor según la cantidad de visibilidad necesaria para activar la animación
//         );

//         if (current) {
//             observer.observe(current);
//         }

//         return () => {
//             if (current) {
//                 observer.unobserve(current);
//             }
//         };
//     }, [hasAnimated]);

//     useEffect(() => {
//         if (hasAnimated && number > 0) {
//             const interval = setInterval(() => {
//                 setDisplayedNumber((prev) => {
//                     if (prev < number) {
//                         return prev + 1;
//                     } else {
//                         clearInterval(interval);
//                         return number;
//                     }
//                 });
//             }, delay); // Ajusta la velocidad de la animación según sea necesario

//             return () => clearInterval(interval);
//         }
//     }, [hasAnimated, number, delay]);

//     return <span ref={ref} className="font-heading text-[2.6rem] font-bold lg:text-5xl xl:text-6xl">{displayedNumber}</span>;
// }


export default AnimatedNumber