import React from "react";
import { GoArrowUpRight } from "react-icons/go";
import { PiBathtubLight, PiBedLight, PiRulerLight } from "react-icons/pi";
import { Link } from "react-router-dom";
import monogramaOrange from '../../assets/monograma/MonogramaN.png'

function PremiunCard({ item }) {

  return (
    <div className="lg:w-[24rem] w-[30rem] hover:opacity-70">
      <div className="relative" >
        {
          item.img01 ? (
            <img
              className="object-cover h-[16rem] w-[30rem]"
              alt="imagen-propiedad"
              src={`https://ivevkdipktjjgbjnwtuc.supabase.co/storage/v1/object/public/${item.img01}`}
            />
          ) : (
            <img
              className="object-contain h-[16rem] w-[30rem]"
              alt="imagen-propiedad"
              src={monogramaOrange}
            />
          )
        }
        <div className="bg-gray-50 w-fit px-2 py-1 absolute top-5 left-5">
          <h3>{item?.moneda + " " + item?.precio}</h3>
        </div>
      </div>
      <div className="w-full flex py-4">
        <div className="w-[80%]">
          <div className="pb-4">
            <h2 className="font-bold">{item.titulo || "------"}</h2>
            <p className="font-hairline text-xs text-gray-500">
              {`${item.direccionCalle || item.direccion_calle || "---"} #${item.direccionNumero || item.direccion_numero || ""} - ${item.provincia || ""} ${item.ciudad ? "-" + item.ciudad : ""}`}
            </p>
          </div>
          <div className="flex border-t border-b items-center w-[85%] justify-between">
            <div className="flex gap-1 items-center">
              <PiBedLight className="text-orange-500" />
              <p className="text-sm">{item.habitaciones}Hb</p>
            </div>
            <span className="border-l m-2 h-6"></span>
            <div className="flex gap-1 items-center">
              <PiBathtubLight className="text-orange-500" />
              <p className="text-sm">{item.banos}Bñ</p>
            </div>
            <span className="border-l m-2 h-6"></span>
            <div className="flex gap-1 items-center">
              <PiRulerLight className="text-orange-500" />
              <p className="text-sm">
                {item.metros_c}m<span className="text-xs align-top text-gray-500">2</span>
              </p>
            </div>
          </div>
        </div>
        <Link to={`/propiedad/${item.id}`} className="w-[20%] flex flex-col items-center justify-center cursor-pointer">
          <div className="border border-gray-600 rounded-full p-1">
            <GoArrowUpRight className="bg-gray-200 w-8 h-8 p-2 rounded-full" />
          </div>
          <p className="text-xs text-gray-500">ver más</p>
        </Link>
      </div>
    </div>
  );
}

export default PremiunCard;
