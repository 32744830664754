import React from 'react';
import AnimatedNumber from './pureComponentes/AnimatedNumber';
import AOS from "aos";
import "aos/dist/aos.css";


function Stats() {
    AOS.init();
 
  return (
    <div
    data-aos="flip-down"
    data-aos-duration="2000"
    className="mx-auto max-w-6xl px-4 py-4 sm:px-6 md:px-24 md:py-8 lg:px-8 ">
    <div className="row-gap-8 grid grid-cols-2 md:grid-cols-4">

        <div className="mb-12 text-center md:mb-0 md:border-r-2 dark:md:border-slate-500">
            <div className="font-heading text-[2.6rem] font-bold lg:text-5xl xl:text-6xl">
                <AnimatedNumber number={1350} delay={4} />
                
            </div>
            <p className="text-sm font-medium uppercase tracking-widest text-slate-400   lg:text-base">
                Visitantes
            </p>
        </div>

        <div className="mb-12 text-center md:mb-0 md:border-r-2 dark:md:border-slate-500">
            <div className="font-heading text-[2.6rem] font-bold  lg:text-5xl xl:text-6xl">
                <AnimatedNumber number={5} delay={50} />
            </div>
            <p className="text-sm font-medium uppercase tracking-widest text-slate-400 lg:text-base">
                Ciudades
            </p>
        </div>

        <div className="mb-12 text-center md:mb-0 md:border-r-2 dark:md:border-slate-500">
            <div className="font-heading text-[2.6rem] font-bold  lg:text-5xl xl:text-6xl">
            <AnimatedNumber number={200} delay={10} />
            </div>
            <p className="text-sm font-medium uppercase tracking-widest text-slate-400 lg:text-base">
                Propiedades
            </p>
        </div>

        <div className="mb-12 text-center md:mb-0 md:border-r-2 dark:md:border-slate-500">
            <div className="font-heading text-[2.6rem] font-bold  lg:text-5xl xl:text-6xl">
            <AnimatedNumber number={484} delay={10} />K
            </div>
            <p className="text-sm font-medium uppercase tracking-widest text-slate-400 lg:text-base">
                Metros<span className="text-xs align-top text-gray-500 ">2</span>
            </p>
        </div>

    </div>

</div>
  );
}



export default Stats;
