import React, { useEffect, useState } from "react";
import Hero from "../Hero";
import Features from "../Features";
import Funtionality from "../Funtionality";
import Brochure from "../Brochure";
import ExplorePremiun from "../ExplorePremiun";
import Stats from "../Stats";
//import Cities from "../Cities";
import FAQs from "../FAQs";
import Team from "../Team";
import Tourism from "../Tourism";
import ManagePropertyBanner from "../ManagePropertyBanner";
import Partners from "../Partners";
import { supabase } from "../../supabaseClient";
// import TextCarousel from "../TextCarousel";

function Landing() {
  const [tituloWeb, setTituloWeb] = useState({});
  const [propuestaWeb, setPropuestaWeb] = useState({});
  const [funcionalidadesWeb, setFuncionalidadesWeb] = useState({});
  const [adminWeb, setAdminWeb] = useState({});
  const [faq, setFAQ] = useState({});
  // eslint-disable-next-line
  const [userId, setUserId] = useState("");
// eslint-disable-next-line
  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        setUserId(user.id);
        console.log(user);
      } else {
        console.log("No hay usuario logueado");
        setUserId("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   getUser();
  // }, []);

  const fetchData = async () => {
    try {
      const { data, error } = await supabase.from("content-web").select("*");
      if (error) throw error;

      if (data) {
        setTituloWeb(data.find((item) => item.content === "titulo-web"));
        setPropuestaWeb(data.find((item) => item.content === "propuesta-web"));
        setFuncionalidadesWeb(
          data.find((item) => item.content === "funcionalidades-web")
        );
        setAdminWeb(data.find((item) => item.content === "administracion-web"));
        setFAQ(data.find((item) => item.content === "faqs"));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Hero props={tituloWeb} />
      <Features props={propuestaWeb} />
      <Funtionality props={funcionalidadesWeb} />
      {/* <TextCarousel /> */}
      {/* <Cities /> */}
      <Tourism/>
      <Brochure title={'Catálogo de propiedades'} />
      <ExplorePremiun />
      <Stats />
      <ManagePropertyBanner props={adminWeb} />
      <Team/>
      <Partners/>
      <FAQs props={faq} />
    </>
  );
}

export default Landing;
