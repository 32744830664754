import React, { useState } from 'react'
import { supabase } from '../../supabaseClient';
import imgBackground from '../../assets/img/login.jpg'
import logo from '../../assets/horizontal/logoHN.png'
import Cookies from "js-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');



  const handleLogin = async (e) => {
    e.preventDefault();

    const response = await supabase.auth.signInWithPassword({
      email,
      password,
    })

    if (response.error) {
      console.error('Error login', toast('Verifique email y contraseńa','danger' ) );
      return;
    }

    if (response) {
      Cookies.set('auth_token', response.data.session.access_token, { expires: 1 });
      Cookies.set('user', response.data.user, { expires: 1 });
      toast.success('Login exitoso','success' );
      setEmail('')
      setPassword('')
      setTimeout(()=>{
        window.location.href = '/admin/dashboard';
      }, 1500)
    
      return;
    }
    console.log('Login successful', response.data);




  };

  return (
    <main className='w-full h-screen bg-orange-600 flex items-center'>
      {/* Imagen de fondo, visible solo en pantallas grandes */}
      <div className="hidden lg:block lg:w-[50%] w-full h-screen">
        <img
          className='w-full h-screen'
          src={imgBackground}
          alt="Background"
        />
      </div>

      {/* Formulario y logo, siempre visible, pero centrado en pantallas medianas */}
      <div className='bg-gray-50 lg:w-[50%] w-full h-screen flex flex-col items-center justify-center'>
        <img
          className='w-[400px] inline-block mb-4'
          src={logo}
          alt="Logo"
        />
        <form onSubmit={handleLogin} className="flex flex-col items-center space-y-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
            className="px-4 py-2 border border-gray-300 rounded w-[250px]"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            className="px-4 py-2 border border-gray-300 rounded w-[250px]"
          />
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
            ingresar
          </button>
        </form>
      </div>
      <ToastContainer />
    </main>


  );
}

export default Login