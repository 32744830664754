import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import teamPic01 from '../assets/team/josefinaDopico.png'
import teamPic02 from '../assets/team/natalia.png'
import teamPic03 from '../assets/team/morenoBelen.jpeg'
import teamPic04 from '../assets/team/victoria.png'
import teamPic05 from '../assets/team/mario.png'
import teamPic06 from '../assets/team/casaleti.png'
import TeamCard from "./pureComponentes/TeamCard";

const teamPhotos = [
  { name: "Dopico Spampinato, Josefina Ivana", profession: `Martillera, corredora pública e inmobiliaria MP: 04-5837`, src: teamPic01 },
  { name: "Casaletti, Mario Augusto", profession: "Asesor de negocios", src: teamPic05 },
  { name: "Cortes, Natalia", profession: "Departamento legal", src: teamPic02 },
  { name: "Moreno, Belén", profession: "Departamento de Marketing", src: teamPic03 },
  { name: "Passegi,  María del Carmen", profession: "Departamento contable", src: teamPic04 },
  { name: "Casaletti, Ignacio Valentin", profession: "Soporte técnico", src: teamPic06 },
];

const Team = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className=" lg:pb-20 lg:pt-[100px] pt-10">
      <div className="container mx-auto">
        <div className="mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center">
              <span className="mb-2 block text-5xl font-semibold text-primary">
                Nuestro equipo
              </span>
              <p className="text-base text-body-color dark:text-dark-6">
                Conoce a nuestros expertos de JDYC Negocios Inmobiliarios: el equipo detrás de cada transacción exitosa.
              </p>
            </div>
          </div>
        </div>
        <div className="mx-4 flex flex-wrap justify-center">
          {teamPhotos.map(photo => (
            <TeamCard
              key={photo.name} // Asumiendo que 'name' es único, o puedes usar otro identificador
              name={photo.name}
              profession={photo.profession}
              imageSrc={photo.src}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;


